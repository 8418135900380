import http from '@/units/request'
import axios from 'axios'
export let agreementList = null
/** *
 * 获取合同设备列表
 * ***/
export const getagreementList = (data) => {
  if (agreementList !== null && data['page.current'] === 1) {
    agreementList('取消请求') // 如果上一次的请求还在继续，则取消
  }
  return http({
    url: '/is-agreement/agreement/v1/list',
    method: 'get',
    params: data,
    cancelToken: new axios.CancelToken(function (c) {
      agreementList = c
    })
  })
}
/** *
 * 获取合同详情
 * ***/
export const getAgreementInfo = (agreementId) => {
  return http({
    url: `/is-agreement/agreement/v1/info/${agreementId}`,
    method: 'get'
  })
}
/** *
 * 获取合同变更详情
 * ***/
export const getSnapshotInfo = (snapshotsId) => {
  return http({
    url: `/is-agreement/agreement/v1/snapshot/${snapshotsId}`,
    method: 'get'
  })
}
